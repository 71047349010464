// Font stacks
$font-stack-one: 'ubuntu';
$font-stack-three: 'Montserrat';
$code-font: 'Menlo', 'Roboto Mono', 'Roboto';

// Spacing and Sizes
$base-font-size: 1.2em;
$base-line-height: 1.5;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$max-width: 800px;

$break-small: 480px;
$break-medium: 700px;
$break-large: 980px;

$big-circle-size: 30px;
$footer-size: 100px;
$about-image-size: 100px;

// Colors
$accent-color: #ff0f00;//#2358D1;
$underline-color: #E1E1E1;
$light-color: #D4D4D4;
$medium-gray-color: #999;
$light-gray-color: #ccc;
$git-black-color: #333333;
$well-color: #f7f7f7;

/* Solarized Colors */
$sol-base1: #93a1a1;
